import React from "react" 

import Button from "../button/button"
import Hyperlink from "../button/hyperlink"
import RichTextField from "../textfield/rich"
import SectionTitle from "../layout/section-title"

class Block extends React.Component {

    render(){

        const { title, ctaText, link, icon, content, disclaimer, align, locale } = this.props

        const style = (align === 'center') ? 'w-full md:w-3/4 mx-auto' : 'w-full'

        return(
            <div className={"container mx-auto px-4 py-8"}>
                <div className={`${style} text-${align}`}>
                {
                    !icon && title && <SectionTitle title={title} position={align}/>
                }
                {
                    icon && (
                        <div className={"w-full mx-auto"}>
                            <img src={icon.file.url} className={"mx-auto"} alt="" />
                        </div>
                    )
                }
                {
                    content && content.json && !title && (
                        <div className={"font-montserrat-regular"}>
                            <RichTextField json={content.json} locale={locale} />
                        </div>
                    )
                }
                {
                    content && content.json && title && (
                        <div className={"font-montserrat-regular -mt-12"}>
                            <RichTextField json={content.json} locale={locale} />
                        </div>
                    )
                }
                {
                    ctaText && link && (<div className={"my-2 py-4"}>
                                            <Hyperlink href={link} locale={locale}>
                                                <Button label={ctaText} />
                                            </Hyperlink>
                                        </div>
                    )
                }
                {
                    disclaimer && <span className={"font-montserrat-regular text-gray-500 text-xs "}>{disclaimer}</span>
                }
                </div>
            </div>
        )
    }

}

export default Block