import React from "react"
import { Lottie } from '@crello/react-lottie'

import "./festival-header.scss"

class FestivalHeader extends React.Component{

    render(){
            

        return(
            <section>
                <div className={"wide-background hidden sm:hidden md:hidden lg:block w-full relative"} style={{backgroundImage:`url(${this.props.wide})`}}>
                    <div className={"wide-background-content relative"}>
                        {this.props.children}
                    </div>
                    <div className={"lottie"}>    
                      
                            <Lottie
                                config = {{
                                    path: this.props.animation,
                                    loop: true,
                                    autoplay: true,
                                }}
                                width="2586px"
                                height="584px"
                            />  
                        
                    </div>              
                </div>
                <div className={"wide-background-tablet hidden sm:hidden md:block lg:hidden w-full relative"} style={{backgroundImage:`url(${this.props.wide})`}}>
                    <div className={"container mx-auto py-8 text-center"}>
                        <div className={"wide-background-content relative"}>
                            {this.props.children}
                        </div>
                        <div className={"lottie"}>    
                            <Lottie
                                config = {{
                                    path: this.props.animation,
                                    loop: true,
                                    autoplay: true,
                                }}
                                width = "1293px"
                                height = "300px"
                            />  
                        </div>
                    </div>
                </div>
                <div className={"portrait-background block sm:block md:hidden w-full relative"} style={{backgroundImage:`url(${this.props.portrait})`}}>
                    <div className={"container mx-auto pt-4 text-center"}>
                        <div className={"wide-background-content relative"}>
                            {this.props.children}
                        </div>
                        <div className={"lottie"}>    
                            <Lottie
                                config = {{
                                    path: this.props.animation,
                                    loop: true,
                                    autoplay: true,
                                }}
                                width = "733px"
                                height = "643px"
                            /> 
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}

export default FestivalHeader