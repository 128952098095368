import React from "react"
import { graphql } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"

import WiglyLineVertical from "../images/wigly-line-vertical.svg"
import Layout from "../components/layout/layout"
import Button from "../components/button/button"
import Map from "../components/map/map"
import PageTransition from "../components/transition/page"
import FestivalHeader from "../components/festival/festival-header"
import FestivalNavigation from "../components/festival/festival-navigation"
import FestivalBackground from "../components/festival/festival-background"
import SEO from "../components/seo/seo"
import SectionTitle from "../components/layout/section-title"
import PartnerGroup from "../components/festival/partner-group"
import Block from "../components/block/block"
import DoubleBlock from "../components/block/double"

class TransportTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.pageTransition = React.createRef()
  }

  animate() {
    this.pageTransition.current.play()
  }

  render() {
    const locale = this.props.pageContext.locale
    const { location } = this.props.data
    return (
      <Layout theme="light" path={this.props.path} locale={locale} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
        <SEO title={location.title} />
        <FestivalNavigation
          locale={locale}
          links={location.navigation.links}
          color={"#F5867B"}
        />
        <FestivalHeader wide={location.theme.widescreenHeaderImage.file.url} portrait={location.theme.portraitHeaderImage.file.url} animation={location.theme.animation.file.url}>
          <div className={"container mx-auto py-12 sm:py-4 md:py-0 lg:py-16 text-center sm:leading-none md:leading-tight lg:leading-tight tracking-wide"}>
            <div className={"mx-auto text-center"}>
              <p className={"font-formula-bold text-lg lg:text-2xl text-white uppercase tracking-wider"}>{`the drop festival ${location.title}`}</p>
              <p className={"font-montserrat-extra-bold text-3xl md:text-7xl lg:text-9xl text-white uppercase"}>general info</p>
              <p className={"font-montserrat-regular sm:text-sm md:text-md lg:text-lg text-white w-11/12 md:w-2/3 lg:w-1/2 mx-auto"}>For information regarding The Drop Festival transport & parking please check our PDF document below </p>
              <div className={"mx-auto text-center my-6 md:py-1 lg:py-4"}>
                <a href={location.transportPdf.file.url} target="_blank" rel="noopener noreferrer">
                  <Button label={"download our pdf guide"} type={"solid"} />
                </a>
              </div>
            </div>
          </div>
        </FestivalHeader>
        <FestivalBackground wide={location.theme.widescreenBackground.file.url}>
            <div>
              {location.privateTransport.map((item, index) => {
                return (
                  <DoubleBlock
                    {...item}
                    align={"left"}
                    locale={locale}
                    key={`private-${index}`}
                  />
                )
              })}
            </div>
            <div className={"container mx-auto px-8"}>
              <SectionTitle
                title={"Jump on public transport"}
                position={"center"}
              />
              <div className={"flex flex-wrap -mt-12"}>
                {
                  location.publicTransport.map((item, index) => {

                    let seperator = ''

                    if(index !== (location.publicTransport.length-1)){
                      seperator = (<img className={"hidden md:block absolute"} src={WiglyLineVertical} style={{right:'0',top:'25%'}} alt="" />)
                    }

                    return (
                      <div
                        className={"w-full md:w-1/2 md:-mt-8 mb-20 mx-auto relative"}
                        key={`item-${index}`}
                      >
                        <Block {...item} align={"center"} />
                        {seperator}
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <DoubleBlock {...location.parking} align={"left"} locale={locale} />
            <Map
              lat={location.map.lat}
              lon={location.map.lon}
              venue={location.venue}
            />
            <PartnerGroup partners={location.partners} locale={locale} />
        </FestivalBackground>
        <TransitionPortal>
          <PageTransition ref={this.pageTransition} />
        </TransitionPortal>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query TransportPageQuery($slug: String!, $locale: String!) {
    location: contentfulLocation(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      title
      slug
      date
      venue
      map {
        lon
        lat
      }
      transportPdf {
        file {
          url
        }
      }
      privateTransport {
        ... on ContentfulBlock {
          title
          slug
          images {
            file {
              url
            }
          }
          ctaText
          link
          content {
            json
          }
        }
      }
      publicTransport {
        ... on ContentfulBlock {
          title
          slug
          images {
            file {
              url
            }
          }
          icon {
            file {
              url
            }
          }
          ctaText
          link
          content {
            json
          }
        }
      }
      parking {
        ... on ContentfulBlock {
          title
          slug
          images {
            file {
              url
            }
          }
          ctaText
          link
          content {
            json
          }
        }
      }
      partners {
        ... on ContentfulPartner {
          slug
          logo {
            file {
              url
            }
          }
        }
      }
      navigation {
        ... on ContentfulNavigation {
          links {
            ... on ContentfulLink {
              title
              href
            }
          }
        }
      }
      theme {
        ... on ContentfulTheme {
          logo {
            file {
              url
            }
          }
          animation{
            file{
              url
            }
          }
          portraitBackground {
            file {
              url
            }
          }
          portraitHeaderImage {
            file {
              url
            }
          }
          widescreenBackground {
            file {
              url
            }
          }
          widescreenHeaderImage {
            file {
              url
            }
          }
          colorPrimary
          colorSecondary
        }
      }
    }
  }
`

export default TransportTemplate
