import React from "react"

class FestivalNavigation extends React.Component{

    render(){

        let cleanLinks = []

        this.props.links.forEach((link) => {
            cleanLinks.push({
                href: `/${this.props.locale}${link.href}`,
                title:link.title
            })
        })

        return(
            <div className={'hidden md:block'} style={{backgroundColor:this.props.color}}>
                <div className={"container mx-auto md:px-4 lg:px-20"}>
                    <div className={"flex justify-center"}>
                    {
                        cleanLinks.map((link,index) => {
                            return(
                                <div className={"flex flex-grow text-center py-4"} key={`link-${index}`}>
                                    <a href={link.href} className={"mx-auto"}>
                                        <span className={"text-white text-sm font-montserrat-extra-bold uppercase"}>{link.title}</span>
                                    </a>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
        )
    }

}

export default FestivalNavigation