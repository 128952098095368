import React from "react" 
import Button from "../button/button"
import RichTextField from "../textfield/rich"
import SectionTitle from "../layout/section-title"
import Hyperlink from "../button/hyperlink"

class DoubleBlock extends React.Component {

    render(){

        const { title, ctaText, link, content, align, locale } = this.props

        return(
            <div className={"container mx-auto px-8 my-12"}>
                <div className={"flex flex-wrap"}>
                    {
                        align === 'left' && (
                            <div className={"mx-auto w-full md:w-1/2 pr-20"}>
                                {
                                    title && (<SectionTitle title={title} position={align} />)
                                }
                            </div>
                        )
                    }

                    {
                        align === 'right' && (
                            <div className={"mx-auto w-full md:w-1/2 pr-20 block md:hidden"}>
                                {
                                    title && (<SectionTitle title={title} position={"left"} />)
                                }
                            </div>
                        )
                    }
                    
                    <div className={"mx-auto w-full md:w-1/2 md:mt-12"}>
                        <div className={"font-montserrat-regular"}>
                            <RichTextField json={content.json} locale={locale} />
                            {
                            ctaText && link && (
                                                <div className={"my-4"}>
                                                  <Hyperlink href={link} locale={locale}>
                                                        <Button label={ctaText} />
                                                  </Hyperlink>
                                                </div>
                                            )
                            }
                        </div>
                    </div>
                    {
                        align === 'right' && (
                            <div className={"mx-auto w-full md:w-1/2 pl-20 hidden md:block"}>
                                {
                                    title && (<SectionTitle title={title} position={'left'} />)
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

}

export default DoubleBlock
