import React from "react"

class FestivalBackground extends React.Component{

    render(){
        return(
            <div className={"wide-wallpaper w-full"} style={{backgroundImage:`url(${this.props.wide})`}}>
                {this.props.children}
            </div>
        )
    }

}

export default FestivalBackground