import React from "react"
import GoogleMapReact from 'google-map-react'

import MarkerIcon from "../../images/marker.svg"
import styles from "./styles";

const Marker = ({ src }) => <img src={src} alt="" />

class Map extends React.Component {

    render(){
        return(
            <div className={"container mx-auto px-8"}>
                <div
                    style={
                        {height:'570px'}
                    }
                    className={"my-8 relative"}
                >
                    <div 
                        className={"bg-coral ml-3 w-11/12 sm:w-11/12 md:w-1/2 lg:w-1/3 p-4 absolute text-center md:text-left"}
                        style={
                            {
                                zIndex:1000,
                                bottom:'-50px'
                            }
                        }
                    >
                        <p className={"font-formula-light text-white uppercase tracking-widest"}>venue location</p>
                        <p className={"font-agrandir text-white text-xl"}>{this.props.venue}</p>
                    </div>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyCpnevIjwZN2c6HguM9QuJvcw918movgPA" }}
                        defaultCenter={{lat:this.props.lat, lng:this.props.lon}}
                        defaultZoom={17}
                        options={{ styles }}
                    >
                        <Marker
                            lat={this.props.lat}
                            lng={this.props.lon}
                            src={MarkerIcon}
                        />
                    </GoogleMapReact>
                </div>
            </div>
        )
    }


}

export default Map