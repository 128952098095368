import React from "react"

import PartnerThumbnail from "../thumbnail/partner"
import SectionTitle from "../layout/section-title"

class PartnerGroup extends React.Component {

    render(){
        return(
            <div className={"container mx-auto py-4"}>
                <SectionTitle title={"Our partners"} position={"center"} />
                <div className={"flex flex-wrap align-center -mt-20 md:-mt-16 lg:-mt-10 pb-12 relative"}>
                {
                    this.props.partners.map((partner, index) => {
                        return(
                        <div className={"w-1/2 px-8 my-4 sm:w-1/2 md:w-1/4 md:px-8 md:py-2 lg:w-1/12 lg:px-0 lg:pt-0 lg:my-0 mx-auto"} key={index}>
                            <PartnerThumbnail
                                image={partner.logo.file.url}
                                href={`/${this.props.locale}/partners/${partner.slug}/`}
                            />
                        </div>
                        )
                    })
                }
                </div>
            </div>
        )
    }

}

export default PartnerGroup