import React from "react"
import { Link } from "gatsby"

import "./thumbnail.scss"

class PartnerThumbnail extends React.Component{

    render(){

        return(
            <Link to={this.props.href}>
                <img className={"mx-auto"} src={this.props.image} alt="" />
            </Link>
        )
    }

}

export default PartnerThumbnail